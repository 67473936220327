<template>
  <div>
        <div>
          <div style="flex-direction: column-reverse" class="flex justify-between lg:flex-row md:flex-col items-center">
          <div>
              <b-button
                data-cy="add-btn"
                variant="primary"
                class="mt-6 bg-h-secondary px-12 py-3"
                color="primary"
                type="gradient"
                @click="showSlider=!showSlider">اضافه کردن کلید SSH
              </b-button>
            </div>
            <div>
              <img width="60" src="../../../assets/images/elements/icon/ssh.png" />
            </div>
      </div>
    <b-sidebar
     :visible="showSlider"
      id="sidebar-backdrop"
      backdrop
      right
      shadow
      z-index="99999"
      bg-variant="h-primary-light"
    >
    <template #title-close>
    </template >
        <div class="ml-2 ">
            <h5 class="text-white">اضافه کردن کلید SSH</h5>
        </div>
     <vs-divider></vs-divider>
      <div class=" my-base p-2" >
        <b-form @submit.prevent.stop class="mt-base" id="ssh-form" label-for="backdrop-variant">
            <label class="text-white" >کلید SSH : </label>
           <b-form-textarea dir="ltr"  name="ssh" id="textarea-rows" v-model="sshKey" placeholder="Starts with ssh-ed25529.. or ssh-rsa..." rows="8" class="bg-h-primary text-white"></b-form-textarea>
           <label class=" mt-base" >عنوان :  </label>
            <b-form-input  dir="ltr" name="title" class="" v-model="sshTitle" placeholder="Title of ssh-key"></b-form-input>
           <b-button type="submit" @click="addKey()" variant="info" class="mt-base w-full bg-h-secondary">اضافه کردن</b-button>

        </b-form>
      </div>
    </b-sidebar>
  </div>
      <!--***************** Table **********************  -->
      <b-card data-cy="table" class="overflow-hidde  n mt-6" border-variant="info">
            <b-table class="text-center align-middle" selectable :stacked="windowWidth < 800" head-variant="dark"
            responsive="md" :items="keyList " :fields="fields" striped >
            <template #cell(عملیات)="row">
              <div class="flex justify-center">
                <vs-button
                  @click="deletePromptDataSet(row.item.id)"
                  color="danger"
                  size="small"
                  radius
                  type="gradient"
                  icon="delete"
                >
                </vs-button>
              </div>
            </template>
            </b-table>
      </b-card>
        <!-- delete promp -->
        <div>
              <vs-popup  :active.sync="deletePrompt" title="حذف هایو ابر">
                <div>
                  <p>آیا از حذف اطمینان دارید؟</p>
                  <vs-button class="rounded float-right mr-base" color="danger" @click="deleteKey">حذف</vs-button>
                </div>
          </vs-popup>
        </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showSlider : false,
      fields  : [{key:'ssh_key_title', label:'عنوان کلید'}, 'عملیات'],
      keyList : [],
      sshKey   : '',
      sshTitle : '',
      deletePrompt : false,
      delID : null
    }
  },
  methods : {
    deletePromptDataSet (id) {
      this.deletePrompt = true
      this.delID = id

    },
    addKey () {
      this.$http.post('/ssh/key/add', {ssh_key_title : this.sshTitle, ssh_key_body: this.sshKey}).then((result) => {
        this.$vs.notify({text: result.data.message})
      }).catch((err) => {
        this.$vs.notify({text: err.response.data.message})
      }).finally(() => {
        this.getKeyList()
        this.showSlider = false
      })
    },
    getKeyList () {
      this.$http.get('/ssh/key/list').then((res) => {
        this.$vs.loading.close()
        const data = res.data.params

        data.data.forEach(el => { el.isActive = false })
        // this.countdomainPage = data.last_page
        this.keyList = data.data
      }).catch((err) => {

        this.keyList = []

        this.$vs.notify({text: err.response.data.message, color: 'danger'})

      })
    },
    deleteKey () {
      this.$http.post('/ssh/key/delete', {id: this.delID}).then((result) => {
        this.$vs.notify({text: result.data.message, color: 'danger'})
      }).catch((err) => {
        this.$vs.notify({text: err.response.data.message})
      }).finally(() => {
        this.deletePrompt = false
        this.getKeyList()
      })
    }

  },
  created () {
    this.getKeyList()
  },
  computed: {
    windowWidth () {
      return this.$store.state.windowWidth
    }
  }
}
</script>
